<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-spacer />
    <v-btn class="ml-2 text-h4" min-width="0" text v-if="token.length == 0">
      <v-icon>mdi-account</v-icon>登入
    </v-btn>
    <v-btn class="ml-2 text-h4" min-width="0" text v-else @click="logout">
      <v-icon>mdi-logout</v-icon>登出
    </v-btn>
  </v-app-bar>
</template>

<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  computed: {
    ...mapState(["drawer", "token"]),
  },

  methods: {
    ...mapActions(["submitToken"]),
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      this.submitToken({
        username: "",
        token: "",
      });
      this.$router.push("/login");
    },
  },
  watch: {
    token() {
      if (!this.token) {
        this.$store.commit("SET_DRAWER", false);
      } else {
        this.$store.commit("SET_DRAWER", true);
      }
    },
  },
};
</script>
